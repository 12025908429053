import React, { useEffect, useState, useContext } from 'react'
import { Styled } from './NavItems.styled'
import { Link } from 'react-router-dom'
import {
  ArrowLeftMenu,
  Doubt,
  Receipt,
  Calendar,
  Exit,
} from 'components/Icons/Icons'
import arrowMobile from 'assets/images/ico/ico-arrow-left-white.png'
import CheckAzul from 'assets/images/ico/check-azul.png'
import CheckBranco from 'assets/images/ico/check-branco.png'

import LinkToShowCase from 'components/LinkToShowCase'
import LinkToShopping from 'components/LinkToShopping'
import { bindActionCreators } from 'redux'
import { setModalShowRedux } from 'store/modal/actions'
import { connect } from 'react-redux'
import { useCount } from 'util/context/Count'
import { TabContext } from 'components/Tab/Tab'

import pushDataLayerGTM from 'util/pushDataLayerGTM'
const NavItemsPrivate = ({ showModal, ...props }) => {
  const { setCount, count } = useCount()
  const { handleChangeTab, myFunc } = useContext(TabContext)

  const [showMenu, setShowMenu] = useState(false)
  const [state, setState] = React.useState({
    isLoggedIn: JSON.parse(localStorage.getItem('auth-token')),
    isVisibleParaMim: false,
    isVisibleMeusPontos: false,
  })
  useEffect(() => {
    let showMenu = props.showMeusVouchers
    setShowMenu(showMenu)
  })

  const clickLinkMenu = (valueEvent) => {
    setCount(0)
    setState({
      ...state,
      isVisibleParaMim: false,
      isVisibleMeusPontos: false,
    })
    if (window.innerWidth < 1024) {
      props.CloseMenu()
    }

    let category = 'geral'
    let action = `clique:${props.position || 'header'}`
    let label = valueEvent
    pushDataLayerGTM(category, action, label)
  }
  return (
    <Styled.Wrap>
      <Styled.primeiroNivel>
        <Styled.itemPrimeiroNivel className="itemPrimeiroNivel">
          <Styled.linkMenu
            to={'/sobre-o-portoplus'}
            onClick={() => clickLinkMenu('sobre-o-portoplus')}
          >
            <span>Sobre o PortoPlus</span>
          </Styled.linkMenu>
        </Styled.itemPrimeiroNivel>

        <Styled.itemPrimeiroNivel className="itemPrimeiroNivel">
          <Styled.linkMenu
            ativo={state.isVisibleParaMim}
            onClick={() => {
              setState({ ...state, isVisibleParaMim: !state.isVisibleParaMim })
            }}
          >
            <span>Para mim</span>
            <Styled.iconeSeta
              className="iconeSeta"
              ativo={state.isVisibleParaMim}
            >
              <ArrowLeftMenu />
              <img src={arrowMobile} alt="" />
            </Styled.iconeSeta>
          </Styled.linkMenu>

          <Styled.Submenu className="submenu" ativo={state.isVisibleParaMim}>
            <ul>
              {/* {showMenu && ( */}
              <Styled.itemSegundoNivel className="itemSegundoNivel">
                <Styled.linkMenu
                  to={'/meus-vouchers'}
                  onClick={() => clickLinkMenu('meus-vouchers')}
                >
                  <Styled.iconeMenu></Styled.iconeMenu>
                  <span>Meus Vouchers</span>
                </Styled.linkMenu>
              </Styled.itemSegundoNivel>
              {/* )} */}

              <Styled.itemSegundoNivel className="itemSegundoNivel">
                <Styled.linkMenu
                  to={'/meus-relacionamentos'}
                  onClick={() => clickLinkMenu('meus-relacionamentos')}
                >
                  <Styled.iconeMenu></Styled.iconeMenu>
                  <span>Meus Relacionamentos</span>
                </Styled.linkMenu>
              </Styled.itemSegundoNivel>

              {state.isLoggedIn?.typePerson === 'Physics' && (
                <Styled.itemSegundoNivel className="itemSegundoNivel">
                  <Styled.linkMenu
                    to={'/faq/cartao'}
                    onClick={() => clickLinkMenu('faq-cartao')}
                  >
                    <Styled.iconeMenu></Styled.iconeMenu>
                    <span>FAQ Cartão</span>
                  </Styled.linkMenu>
                </Styled.itemSegundoNivel>
              )}

              {state.isLoggedIn?.typePerson === 'Legal' && (
                <Styled.itemSegundoNivel
                  className="itemSegundoNivel"
                >
                  <Styled.linkMenu
                    to={'/faq/corretor'}
                    onClick={() => clickLinkMenu('faq-corretor')}
                  >
                    <Styled.iconeMenu></Styled.iconeMenu>
                    <span>FAQ Corretor</span>
                  </Styled.linkMenu>
                </Styled.itemSegundoNivel>
              )}
              <Styled.itemSegundoNivel className="itemSegundoNivel">
                <Styled.linkMenu
                  to={'/faq/descontos'}
                  onClick={() => clickLinkMenu('faq-descontos')}
                >
                  <Styled.iconeMenu></Styled.iconeMenu>
                  <span> FAQ Compras Com Desconto</span>
                </Styled.linkMenu>
              </Styled.itemSegundoNivel>
            </ul>
          </Styled.Submenu>
        </Styled.itemPrimeiroNivel>

        <Styled.itemPrimeiroNivel className="itemPrimeiroNivel">
          <Styled.linkMenu
            ativo={state.isVisibleMeusPontos}
            onClick={() => {
              setState({
                ...state,
                isVisibleMeusPontos: !state.isVisibleMeusPontos,
              })
            }}
          >
            <span>Meus Pontos</span>
            <Styled.iconeSeta
              className="iconeSeta"
              ativo={state.isVisibleMeusPontos}
            >
              <ArrowLeftMenu />
              <img src={arrowMobile} alt="" />
            </Styled.iconeSeta>
          </Styled.linkMenu>

          <Styled.Submenu className="submenu" ativo={state.isVisibleMeusPontos}>
            <ul>
              <Styled.itemSegundoNivel className="itemSegundoNivel">
                <Styled.linkMenu
                  to={'/meu-extrato'}
                  onClick={() => clickLinkMenu('meu-extrato')}
                >
                  <Styled.iconeMenu></Styled.iconeMenu>
                  <span>Meu Extrato</span>
                </Styled.linkMenu>
              </Styled.itemSegundoNivel>

              <Styled.itemSegundoNivel className="itemSegundoNivel">
                <Styled.linkMenu
                  to={'/historico-de-resgates'}
                  onClick={() => clickLinkMenu('historico-de-resgates')}
                >
                  <Styled.iconeMenu></Styled.iconeMenu>
                  <span>Histórico de Resgates</span>
                </Styled.linkMenu>
              </Styled.itemSegundoNivel>

              <Styled.itemSegundoNivel className="itemSegundoNivel">
                <Styled.linkMenu
                  to={'/programa-anterior'}
                  onClick={() => clickLinkMenu('programa-anterior')}
                >
                  <Styled.iconeMenu></Styled.iconeMenu>
                  <span>Programa Anterior</span>
                </Styled.linkMenu>
              </Styled.itemSegundoNivel>

              {props.isPerson === 'Legal' && (
                <Styled.itemSegundoNivel className="itemSegundoNivel">
                  <Styled.linkMenu
                    to={'/transferencia-de-pontos'}
                    onClick={() => clickLinkMenu('transferencia-de-pontos')}
                  >
                    <Styled.iconeMenu></Styled.iconeMenu>
                    <span>Transferência de Pontos</span>
                  </Styled.linkMenu>
                </Styled.itemSegundoNivel>
              )}

              <Styled.itemSegundoNivel className="itemSegundoNivel">
                <Styled.linkMenu
                  to={'/produtos-porto-seguro'}
                  onClick={() => clickLinkMenu('produtos-porto-seguro')}
                >
                  <Styled.iconeMenu></Styled.iconeMenu>
                  <span>Produtos Porto</span>
                </Styled.linkMenu>
              </Styled.itemSegundoNivel>
            </ul>
          </Styled.Submenu>
        </Styled.itemPrimeiroNivel>
        <Styled.itemPrimeiroNivel className="itemPrimeiroNivel">
          <Styled.wrapLink onClick={() => clickLinkMenu('resgatar-pontos')}>
            <LinkToShowCase>
              <span>Trocar Pontos</span>
            </LinkToShowCase>
          </Styled.wrapLink>
        </Styled.itemPrimeiroNivel>

        {/* <Styled.itemPrimeiroNivel className="itemPrimeiroNivel">
          <Styled.wrapLink onClick={() => clickLinkMenu('shopping')}>
            <LinkToShopping source={'navitem'}>
              <span>Comprar e Ganhar Pontos</span>
            </LinkToShopping>
          </Styled.wrapLink>
        </Styled.itemPrimeiroNivel> */}
      </Styled.primeiroNivel>

      <Styled.MenuMobile>
        <hr />
        <Styled.itensMenuMobile>
          <Styled.ButtonLink
            className={`${count == 1 ? 'active' : ''}`}
            onClick={() => {
              handleChangeTab(1), clickLinkMenu('trocar-pontos')
            }}
          >
            <strong>Trocar Pontos</strong>
          </Styled.ButtonLink>
        </Styled.itensMenuMobile>

        <Styled.itensMenuMobile className="itemPrimeiroNivel">
          <Styled.ButtonLink
            className={`${count == 2 ? 'active' : ''}`}
            onClick={() => {
              handleChangeTab(2), clickLinkMenu('ganhar-pontos')
            }}
          >
            Comprar com <strong> desconto</strong>
          </Styled.ButtonLink>
        </Styled.itensMenuMobile>

        {/* <Styled.itensMenuMobile className="itemPrimeiroNivel">
          <Styled.ButtonLink
            className={`${count == 3 ? 'active' : ''}`}
            onClick={() => {
              handleChangeTab(3), clickLinkMenu('Comprar-desconto')
            }}
          >
            Comprar e <strong> ganhar pontos</strong>
          </Styled.ButtonLink>
        </Styled.itensMenuMobile> */}
      </Styled.MenuMobile>

      <Styled.Sair>
        <Link to={'/logout'} onClick={() => clickLinkMenu('sair')}>
          <Exit />
          <Styled.Exit>Sair</Styled.Exit>
        </Link>
      </Styled.Sair>
    </Styled.Wrap>
  )
}

const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(NavItemsPrivate)
