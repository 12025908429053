import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import openExternalLink from 'util/LinkToShowCase'
import axios from 'shared/http'

const LinkToShowCase = (props) => {
  const [state, setState] = React.useState({
    isLoggedIn: localStorage.getItem('auth-token'),
    linkCatalogo: '',
  })

  useEffect(() => {
    getUrlverTodos()
  }, [])

  const getUrlverTodos = () => {
    if (state.isLoggedIn && state.linkCatalogo === '') {
      let url = '/catalog/v1/webpremios'

      axios
        .get(url)
        .then((response) => {
          setState({ ...state, linkCatalogo: response })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  function verTodos(e) {
    e.preventDefault()
    openExternalLink(state.linkCatalogo.uri)
  }

  return <Link onClick={verTodos}>{props.children}</Link>
}

export default LinkToShowCase
