import axios from 'axios'
import verifyIsLoginActive from 'util/verifyIsLoginActive'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_AWS
})

instance.interceptors.request.use(
  (config) => {
    const usuario = verifyIsLoginActive()
    if (usuario) {
      config.headers.authorization = `Bearer ${usuario.accessToken}`
    }
    config.headers.common = {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_API,
    }

    return config
  },
  (error) => Promise.reject(error)
)

instance.interceptors.response.use(
  (res) => {
    return Promise.resolve(res.data)
  },
  (error) => {
    return Promise.reject(error.response.data)
  }
)

export default instance
