import React, { useEffect, useState, useRef } from 'react'
import { Styled } from './VitrineCarousel.styled'
import CredtCard from '../../../assets/images/ico/credit-card.png'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setModalShowRedux } from 'store/modal/actions'
import LoginBox from 'containers/LoginBox/LoginBox'
import axios from 'shared/http'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

const useMountEffect = (fun) => useEffect(fun, [])

const VitrineCarousel = ({ showModal, list, ...props }) => {
  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()
  useMountEffect(executeScroll)
  const history = useHistory()
  const url = '/catalog/v1/webpremios'
  const [arrayList, setArrayList] = useState([])
  const [linkAux, SetLinkAux] = useState('')
  const [isLoggedIn] = useState(localStorage.getItem('auth-token'))

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1224 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1224, min: 760 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 760, min: 0 },
      items: 1,
    },
  }

  useEffect(() => {
    let array = []
    list?.map((itens) => {
      if (array.length === 4) {
        return setArrayList(array)
      }
      array.push(itens.component.props.list)
    })
    setArrayList(array)
  }, [list])

  const getUrl = (url) => {
    //let url = '/catalog/v1/webpremios'
    axios
      .get(url)
      .then((response) => {
        window.open(response.uri, '_blank')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleCardLogin = (url) => {
    if (isLoggedIn) {
      return window.open(url, '_blank')
    }
    handleIsLogin(url)
  }

  const authToken = localStorage.getItem('auth-token') || null
  const handleIsLogin = async (url, login, id) => {
    const arrAux = url?.split('/')
    const identifier = arrAux[arrAux.length - 1]
    const urlAux = `/catalog/v1/showcase/${identifier}`
    if (urlAux != undefined) {
      if (authToken != null) {
        getUrl(id, urlAux)
      } else {
        history.push('/Login', { id: id, url: urlAux })
      }
    } else {
      history.push('/Login')
    }
  }

  const getUrlverMais = (urls, login) => {
    if (authToken != null) {
      getUrl(urls)
    } else {
      history.push('/Login', { url: urls })
      // showModal({
      //   show: true,
      //   content: <LoginBox getUrl={urls} />,
      //   title:
      //     'Preencha o CPF/CNPJ, a senha e faça a validação de segurança para realizar o login.',
      //   width: '600px',
      // })
    }
  }

  return (
    <Container>
      <Row>
        <Styled.Content ref={myRef}>
          <Col>
            <Styled.ContentTxt>
              <Styled.Text>
                <strong>
                  Confira uma prévia do que você encontra lá no nosso
                  marketplace.
                </strong>
              </Styled.Text>
            </Styled.ContentTxt>

            <Carousel
              responsive={responsive}
              infinite={true}
              removeArrowOnDeviceType={['desktop', 'mobile']}
            >
              {arrayList?.map((itens, index) => {
                return (
                  <Card
                    className="m-2 carousel-mkp card-border"
                    onClick={() => handleCardLogin(itens.url)}
                  >
                    <Card
                      className="m-3 card-border-int d-flex align-items-center justify-content-center"
                      style={{ minHeight: '280px' }}
                    >
                      <Card.Img
                        className="p-5"
                        variant="top"
                        src={itens.imageUrl}
                      />
                    </Card>
                    <Card.Body>
                      <Card.Title>{itens.vendorName}</Card.Title>
                      <Card.Text>
                        <Styled.Description>{itens.name}</Styled.Description>
                      </Card.Text>
                      <Card.Text className="mt-4">
                        <Styled.CashBack>
                          De {itens.regularPrice} pts por
                        </Styled.CashBack>
                        <Styled.Pts className="mt-2">
                          {itens.price.toLocaleString('pt-BR')} pts
                        </Styled.Pts>
                        <Styled.ContentPayment>
                          <Styled.Icon src={CredtCard} />
                          <Styled.Payment>
                            Complete com seu cartão
                          </Styled.Payment>
                        </Styled.ContentPayment>
                      </Card.Text>
                    </Card.Body>
                  </Card>

                  // <Styled.Card
                  //   key={index}
                  //   onClick={() => handleCardLogin(itens.url)}
                  // >
                  //   <Styled.CardImg>
                  //     {/* <Styled.InfoDesc>- 15%</Styled.InfoDesc> */}
                  //     <Styled.ContentImg>
                  //       <Styled.Img src={itens.imageUrl} />
                  //     </Styled.ContentImg>
                  //   </Styled.CardImg>
                  //   <Styled.ContentInfo>
                  //     <Styled.Client>{itens.vendorName}</Styled.Client>
                  //     <Styled.Description>{itens.name}</Styled.Description>
                  //     <Styled.CashBack>
                  //       De {itens.regularPrice} pts por
                  //     </Styled.CashBack>
                  //     <Styled.Pts>
                  //       {itens.price.toLocaleString('pt-BR')} pts
                  //     </Styled.Pts>
                  //     <Styled.ContentPayment>
                  //       <Styled.Icon src={CredtCard} />
                  //       <Styled.Payment>
                  //         Complete com seu cartão
                  //       </Styled.Payment>
                  //     </Styled.ContentPayment>
                  //   </Styled.ContentInfo>
                  // </Styled.Card>
                )
              })}
            </Carousel>
          </Col>
          <Col>
            <Styled.ContentBtnMkp>
              <Button className="w-100 h-35" onClick={() => getUrlverMais(url)}>
                Ver Mais
              </Button>
            </Styled.ContentBtnMkp>
          </Col>
        </Styled.Content>
      </Row>
    </Container>
  )
}

const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(VitrineCarousel)
